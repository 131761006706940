const theme = {
  colors: {
    text: "#131E26",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#2D7A6A",
    secondary: "#C99D66",
    customYellow: "#FFDF54",
    light: "#ffffff",
    dark: "#303030",
    lightGrey: "#999999",
  },
  fonts: {
    body: '"Aleo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Aleo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 325, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.094,
    heading: 1.25,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 5,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    kicker: {
      fontSize: "18px",
      letterSpacing: "8px",
      lineHeight: "3.889",
      textTransform: "uppercase",
      color: "secondary",
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      maxWidth: ["750px", "970px", "1320px"],
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: ["1280px"],
      px: [3, 4],
    },
    custom: {
      maxWidth: ["750px", "970px", "1320px"],
      px: [3, 4],
    },
    oneSideLeft: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1280px) / 2) + 1280px)",
      ],
      mr: [0, 0, 0, 0],
      padding: [3, 4],
    },
    oneSideRight: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1280px) / 2) + 1280px)",
      ],
      ml: [0, 0, 0, 0],
      padding: [3, 4],
    },
    oneSide: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1320px) / 2) + 1320px)",
      ],
      padding: [3, 4],
    },
  },
  inputs: {
    primary: {
      borderColor: "primary",
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "primary",
      "&:focus": {
        outline: "none",
        backgroundColor: "primary",
        color: "white",
        border: "1px solid transparent",
      },
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    full: {
      primaryForm: {
        paddingY: 3,
        paddingX: 5,
        cursor: "pointer",
        color: "light",
        bg: "primary",
        borderRadius: "0px!important",
        "&:hover": {
          color: "light",
          bg: "primary",
          svg: {
            stroke: "light",
          },
        },
      },
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      overflowX:"hidden",

      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "dark",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
